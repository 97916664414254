/**
 * Sends custom events to Google Analytics
 */
export const event = (action, { event_category, event_label, value }) => {
  (window as any).gtag("event", action, {
    event_category,
    event_label,
    value,
  });
};

export const handleClick = (event) => {
  const htmlElement = event.target.closest("[data-ga-event]");
  if (!htmlElement) return;

  let eventData;
  try {
    eventData = JSON.parse(htmlElement.dataset.gaEvent);
  } catch (err) {
    console.error("Invalid data-ga-event JSON:", htmlElement.dataset.gaEvent);
    return;
  }

  const {
    category = "interaction",
    action = "click",
    label,
    value = 0,
  } = eventData;

  if (typeof window.gtag === "function") {
    window.gtag("event", action, {
      event_category: category,
      event_label: label ?? window.location.pathname,
      event_value: value,
    });
  } else {
    console.warn("gtag is not defined. Event not sent.");
  }
};

/**
 * Analytics grouping function (categorizes paths)
 */
export function analyticsGrouping(path: string) {
  if (path.includes("/kitas/") || path.endsWith("/kitas")) return "kitas";
  if (path.includes("/events/") || path.endsWith("/events")) return "events";
  if (path.includes("/blog/") || path.endsWith("/blog")) return "blog";
  if (path.includes("/timeline/") || path.endsWith("/timeline"))
    return "timeline";
  if (path.includes("/perks/") || path.endsWith("/perks")) return "perks";
  if (path.includes("/guides/") || path.endsWith("/guides")) return "guides";
  return "other";
}

export const safeGtag = (action, args = {}) => {
  if (typeof window !== "undefined" && typeof window.gtag === "function") {
    // @ts-ignore
    window.gtag(action, ...args);
  } else {
    console.warn(`gtag is not available. Skipped action: ${action}`);
  }
};
