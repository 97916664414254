"use client";
import React, { useRef, useEffect, useState } from "react";
import { Container } from "./Container";

type Props = {
  children: React.ReactNode;
};

type CarouselRefType = HTMLDivElement | null;

let cardWidth = 0;
let margin = 0;
let scrollDistance = 0;

export function Carousel({ children }: Props) {
  const carouselRef = useRef<CarouselRefType>(null);

  useEffect(() => {
    if (carouselRef.current) {
      const firstCard = carouselRef.current.querySelector(
        ".carousel-item",
      ) as HTMLDivElement;

      if (firstCard) {
        const computedStyle = getComputedStyle(firstCard);
        const marginLeft = parseFloat(computedStyle.marginLeft);
        const marginRight = parseFloat(computedStyle.marginRight);
        cardWidth = firstCard.offsetWidth;
        margin = marginLeft + marginRight;
        scrollDistance = cardWidth + margin;
      }
    }
  }, []);

  const scrollLeft = () => {
    if (carouselRef.current && cardWidth > 0 && margin >= 0) {
      carouselRef.current.scrollBy({
        left: -scrollDistance,
        behavior: "smooth",
      });
    }
  };

  const scrollRight = () => {
    if (carouselRef.current && cardWidth > 0 && margin >= 0) {
      carouselRef.current.scrollBy({
        left: scrollDistance,
        behavior: "smooth",
      });
    }
  };

  return (
    <div>
      <button
        onClick={scrollLeft}
        className="btn btn-circle btn-secondary btn-xs absolute -left-1 top-1/2 z-20 m-1 opacity-50"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          className="-translate-x-[2px] rotate-90 transform fill-neutral"
        >
          <path d="M7.41 6.59L12 11.17l4.59-4.58L18 8l-6 6-6-6 1.41-1.41z" />
        </svg>
      </button>
      <div ref={carouselRef} className="carousel w-full">
        {children}
      </div>
      <button
        onClick={scrollRight}
        className="btn btn-circle btn-secondary btn-xs absolute -right-1 top-1/2 z-20 m-1 opacity-50"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          className="translate-x-[2px] rotate-[270deg] transform fill-neutral"
        >
          <path d="M7.41 6.59L12 11.17l4.59-4.58L18 8l-6 6-6-6 1.41-1.41z" />
        </svg>
      </button>
    </div>
  );
}

export default Carousel;

export function CarouselError() {
  return (
    <Container>
      <div className="carousel-centered carousel w-full bg-warning p-6">
        <div className="carousel-item mx-2 flex items-center">
          <div className="text-center">
            <p className="text-sm text-neutral-content">
              🥺 Oops! Something went wrong. Please refresh the page.
            </p>
          </div>
        </div>
      </div>
    </Container>
  );
}
