"use client";
import { useEffect, useState } from "react";
import clsx from "clsx";

export const AutoCarousel = () => {
  const carouselItems = [
    <div key="0" className="card w-full bg-base-100">
      <div className="card-body">
        <p className="italic">
          Pregnancy is such an exciting but also daunting journey, especially as
          an international couple in a big city like Berlin. The meetups with
          the other expecting parents have been a great way to feel connected
          and supported on this journey.
        </p>
        <h2 className="card-title font-light text-secondary">
          —Alina and Andrzej N., Kietzee Club members
        </h2>
      </div>
    </div>,
    <div key="1" className="card w-full bg-base-100">
      <div className="card-body">
        <p className="italic">
          I felt really anxious about finding a Kita in Berlin but using your
          site and reading your blog really helped. It made my search easier and
          - even more importantly - reduced my stress regarding the whole
          process. I didn&apos;t use any other resources, so your website was
          the first and the only stop for me, and it worked!
        </p>
        <h2 className="card-title font-light text-secondary">
          —Ivan M., Kietzee Club member
        </h2>
      </div>
    </div>,
    <div key="2" className="card w-full bg-base-100">
      <div className="card-body">
        <p className="italic">
          Looking for a Kita spot in Berlin is super stressful and Kietzee
          helped me a lot! Your database made the search easier and I was
          delighted to read a positive review of the Kita we ended up choosing.
        </p>
        <h2 className="card-title font-light text-secondary">—Ilaria R.</h2>
      </div>
    </div>,
  ];

  const [activeItem, setActiveItem] = useState(0);

  const handleButtonClick = (index) => {
    setActiveItem(index);
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      setActiveItem((prevItem) => (prevItem + 1) % carouselItems.length);
    }, 4000);

    return () => clearInterval(intervalId);
  }, [carouselItems.length]);

  return (
    <div className="flex justify-center">
      <div>
        <div className="inline-grid place-items-center">
          {carouselItems.map((item, index) => (
            <div
              key={index}
              className={clsx(
                "col-start-1 row-start-1 w-full transition-opacity duration-500 ease-in-out",
                index === activeItem ? "opacity-100" : "opacity-0",
              )}
            >
              {item}
            </div>
          ))}
        </div>
        <div className="flex w-full justify-center gap-2 py-2">
          {carouselItems.map((_, index) => (
            <button
              key={index}
              onClick={() => handleButtonClick(index)}
              className={clsx("btn btn-circle btn-info btn-xs", {
                "btn-outline": activeItem !== index,
              })}
            ></button>
          ))}
        </div>
      </div>
    </div>
  );
};
