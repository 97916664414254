import clsx from "clsx";
import { HTMLProps, ReactNode } from "react";

interface ReusableComponentProps extends HTMLProps<HTMLDivElement> {
  children?: ReactNode;
  extraClasses?: string;
}

export const Container: React.FC<ReusableComponentProps> = ({
  children,
  extraClasses = "",
  ...props
}: {
  children: React.ReactNode;
  extraClasses?: string;
  props?: any;
}) => (
  <div
    className={clsx("mx-auto w-full max-w-screen-xl", extraClasses)}
    {...props}
  >
    {children}
  </div>
);
